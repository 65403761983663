<template>
  <div class="mx-2 my-2">
    <v-card class="mx-auto  ">
      <v-col
        cols="12"
        class="pl-0 pr-0 pb-1"
      >
        <v-row class="px-2 py-2">
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="1"
            class="  py-0 my-0"
          >
            <v-select
              v-model="yearModel"
              :items="yearList"
              label="Yıl"
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            xl="1"
            class="  py-0 my-0"
          >
            <v-select
              v-model="monthModel"
              :items="monthList"
              label="Ay"
              outlined
              dense
            />
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="3"
            xl="2"
            class="  py-0 my-0"
          >
            <v-select
              v-model="santralModel"
              :items="santralDataList"
              multiple
              label="Santral"
              outlined
              dense
            >
              <template v-slot:selection="{item, index}">
                <v-chip v-if="index === 0">
                  <span>{{ item }}</span>
                </v-chip>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                >
                  (+{{ channelModel.length - 1 }} others)
                </span>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="3"
            xl="2"
            class="  py-0 my-0 mb-2"
          >
            <v-text-field
              v-model="search"
              label="Ara"
              single-line
              hide-details
              outlined
              dense
            />
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="2"
            lg="1"
            xl="1"
            class="  py-0 my-0"
          >
            <v-btn
              color="primary"
              @click="getErrorTable"
            >
              Filtrele
            </v-btn>
          </v-col>

          <v-col
            cols="6"
            sm="2"
            md="1"
            lg="1"
            xl="1"
            class="  py-0 my-0"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-btn
                  fab
                  dark
                  small
                  color="cyan"
                  v-on="on"
                  @click="exportDocument"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Export to Excel</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        class="fullgridtable pl-0 pr-0 pb-1"
      >
        <v-card>
          <v-data-table
            :id="santralDataTable"
            dense
            :items-per-page.sync="itemsPerPage"
            :server-items-length="itemsLength"
            :footer-props="{
              'items-per-page-options': [5, 10, 20, 30, 40, 50, 100],
              showFirstLastPage: true,
              itemsPerPageText: 'Limit'
            }"
            :options.sync="options"
            :headers="headers"
            :page.sync="currentPage"
            :loading="loading"
            loading-text="Yükleniyor... Lütfen bekleyiniz"
            :items="desserts"
            class="elevation-1"
          />
        </v-card>
      </v-col>
    </v-card>

    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>
<script>
import * as Notification from '@/api/Notification/notification';
import moment from 'moment';
import ExportDocument from '@/views/components/Shared/ExportDocument.vue';

export default {
  name: 'GeneralStatsHour',
  components: {
    ExportDocument,
  },
  data: () => ({
    santralModel: [],
    chartData: {},
    search: '',
    menu: false,
    modal: false,
    menu2: false,
    itemsPerPage: 20,
    itemsLength: 10,
    headers: [],
    desserts: [],
    channelModel: 'STRINGBOX',
    channel: [
      { text: 'String', value: 'STRINGBOX' },
      { text: 'İnverter', value: 'INVERTER' },
    ],

    type: [
      { text: 'Hata', value: 'Error' },
      { text: 'Uyarı', value: 'Warning' },
    ],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: '',
    chartText: '',
    newData: 'true',
    currentPage: 1,
    loading: false,
    options: {},
    params: {},
    yearModel: parseInt(moment().format('YYYY')),
    monthModel: parseInt(moment().format('MM')),
  }),
  computed: {
    santralDataList() {
      return this.$store.getters.santralDataList;
    },

    monthList() {
      return this.$store.getters.month;
    },
    yearList() {
      return this.$store.getters.clientYearList;
    },
  },
  watch: {
    reportTypeModel() {
      this.getErrorTable();
    },
    search() {},
    options: {
      handler() {
        this.loading = true;
        const {
          sortBy, sortDesc, page, itemsPerPage,
        } = this.options;

        this.params = {
          type: 'Error',
          skip: (page - 1) * itemsPerPage,
          limit: itemsPerPage,
        };

        if (sortBy.length > 0) {
          this.params.orderBys = [{ fieldName: sortBy[0], sortType: sortDesc[0] }];
        }

        this.getErrorTable();
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    setTime(time) {
      return moment(time).format('HH:mm');
    },
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    getErrorTable() {
      this.loading = true;

      this.finishDate = this.startDate;
      this.headers = [
        { text: 'Tip', value: 'type' },
        { text: 'Kod', value: 'code' },
        { text: 'santral', value: 'santral' },
        { text: 'Cihaz', value: 'device' },
        { text: 'Hata sayısı', value: 'ErrorCount' },
        { text: 'lifetime', value: 'lifetime' },
        { text: 'subdevice', value: 'subdevice' },
        { text: 'message', value: 'message' },
      ];

      if (this.santralModel.length > 0) {
        this.params.santral = this.santralModel;
      }

      this.params.year = this.yearModel;
      this.params.month = this.monthModel;
      this.params.type = this.typeModel;

      if (this.searchval) {
        this.params.search = this.searchval;
      }
      const self = this;
      const formdata = {
        condiniton: this.params,
      };

      const tempData = Notification.default.getErrorTime(formdata);
      tempData.then((res) => {
        // console.log(res);
        self.desserts = res.data;
        self.itemsLength = res.count;
        self.forceRerender();
        self.loading = false;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.theme--dark .fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 0%, 100%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
